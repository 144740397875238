import {
  connectorsForWallets,
  darkTheme,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import {
  coinbaseWallet,
  injectedWallet,
  metaMaskWallet,
  rainbowWallet,
  safeWallet,
  tokenPocketWallet,
  trustWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { mainnet, base, bsc, arbitrum, polygon } from "wagmi/chains";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { publicProvider } from "wagmi/providers/public";
import React, { ReactNode } from "react";
import { createConfig, WagmiConfig, configureChains } from "wagmi";

export const rpcUrls: Record<number, string> = {
  [mainnet.id]:
    "https://eth-mainnet.g.alchemy.com/v2/wp_JkO86lXk46k7c20njkmzHWIcz3QmL",
  [bsc.id]: "https://binance.llamarpc.com",
  [base.id]:
    "https://base-mainnet.g.alchemy.com/v2/Uw5Fn33W_FuXw_LAP2-Cy_F5C9law7sh",
  [arbitrum.id]:
    "https://arb-mainnet.g.alchemy.com/v2/vrULZ5WB86nelgEEkfDEGeuKYWb4q661",
  [polygon.id]:
    "https://polygon-mainnet.g.alchemy.com/v2/BNenMR9hsppNc6g78ZP9CJBJUpA44Z1o",
};

const { chains, publicClient } = configureChains(
  [mainnet, base, bsc, arbitrum, polygon],
  [
    jsonRpcProvider({
      rpc: (chain) => ({
        http: rpcUrls[chain.id] || chain.rpcUrls.default.http[0] || "",
      }),
    }),
    publicProvider(),
  ],
);

const projectId =
  process.env["NEXT_PUBLIC_WC_PROJECT_ID"] ||
  "dd5bf4c8f974b01f74f3d578815b3503";

const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [
      injectedWallet({ chains }),
      metaMaskWallet({ chains, projectId }),
      coinbaseWallet({ chains, appName: "Froge Swap" }),
      tokenPocketWallet({ chains, projectId }),
      trustWallet({ chains, projectId }),
      safeWallet({
        allowedDomains: [/gnosis-safe.io$/, /app.safe.global$/],
        chains,
      }),
      rainbowWallet({ chains, projectId }),
      walletConnectWallet({ chains, projectId }),
    ],
  },
]);

export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

interface RainbowKitProviderProps {
  children: ReactNode;
}

const isTest =
  process.env["NEXT_PUBLIC_APP_ENV"] === "test" ||
  process.env["NEXT_PUBLIC_PLAYWRIGHT_ENABLED"] === "true";

export default function RainbowKit({ children }: RainbowKitProviderProps) {
  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider
        chains={chains}
        modalSize="compact"
        theme={darkTheme()}
      >
        {children}
      </RainbowKitProvider>
    </WagmiConfig>
  );
}
