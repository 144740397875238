"use client";

import { Provider } from "react-redux";
import { persistor, store } from "./store";
import type { ReactNode } from "react";
import RainbowKit from "@/app/rainbowkit";
import { Theme } from "@radix-ui/themes";
import { PersistGate } from "redux-persist/integration/react";
import { Toaster } from "@/app/features/toaster";
import { ThemeProvider as NextThemesProvider } from "next-themes";

interface RootProvidersProps {
  children: ReactNode;
}
export default function RootProviders({ children }: RootProvidersProps) {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <RainbowKit>
          <NextThemesProvider attribute="class" defaultTheme="dark">
            <Theme
              accentColor="grass"
              grayColor="olive"
              panelBackground="translucent"
            >
              <Toaster>{children}</Toaster>
            </Theme>
          </NextThemesProvider>
        </RainbowKit>
      </PersistGate>
    </Provider>
  );
}
